<template>
  <div class="app-container">
    <!-- <header-back name='首页'></header-back> -->
    <bottom-menu v-if="bottomShow"></bottom-menu>
    <keep-alive :include="includes">
      <router-view :key="key" />
    </keep-alive>
    <!-- <keep-alive v-if="$route.meta.keepAlive">
      <router-view></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive">
    </router-view> -->
  </div>
</template>
<script>
import HeaderBack from "../components/header-back";
import BottomMenu from "@/components/bottom-menu"; // 底部菜单
export default {
  data() {
    return {
      includes: ["mall"],
    };
  },
  computed: {
    bottomShow() {
      return this.$store.getters["menu/bottomShow"];
    },
    cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key() {
      return this.$route.fullPath;
    },
  },

  created() {},
  components: { BottomMenu, HeaderBack },
  methods: {},
};
</script>
<style>
</style>
