<template>
  <div class="bottom-menu">
    <div
      class="menu-tab"
      :class="{'menu-tab-active':bottomSelectedId==index}"
      v-for="(item,index) in bottomItems"
      :key="item.text"
      @click="handleChange({type: item.type,index: index})"
    >
      <img
        :src="bottomSelectedId==index?item.imgActive:item.imgDefault"
        alt
      />
      <span>{{item.text}}</span>
    </div>
  </div>
</template>

<script>
// 底部导航栏组件
import utils from "@/utils/index";

export default {
  data() {
    return {
      bottomItems: [
        {
          text: "首页",
          icon: "",
          index: 0,
          type: "/",
          imgActive: require("../../assets/image/icon/home-on.png"),
          imgDefault: require("../../assets/image/icon/home-off.png"),
        },
        {
          text: "商城",
          icon: "",
          index: 1,
          // type: "/mall",
          type: "https://m.jf.10086.cn/#/pages/index/index",
          imgActive: require("../../assets/image/icon/cart-on.png"),
          imgDefault: require("../../assets/image/icon/cart-off.png"),
        },
        {
          text: "购物车",
          icon: "",
          index: 2,
          type: "/shop",
          imgActive: require("../../assets/image/icon/shop-on.png"),
          imgDefault: require("../../assets/image/icon/shop-off.png"),
        },
        {
          text: "我的",
          icon: "",
          index: 3,
          type: "/person",
          imgActive: require("../../assets/image/icon/my-on.png"),
          imgDefault: require("../../assets/image/icon/my-off.png"),
        },
      ],
      bottomOptions: {
        activeColor: "#1d98bd",
        fixBottom: true,
        labelKey: "text",
      },
    };
  },
  computed: {
    bottomSelectedId() {
      let id = this.$store.state.menu.bottomId;
      if (utils.getSessionStorage("bottomId")) {
        id = Number(utils.getSessionStorage("bottomId"));
      }
      return id;
    },
  },
  watch: {
    // $route(val) {
    // let index = 0;
    // if (val.name === "mall") {
    //   index = 1;
    // } else if (val.name === "shop") {
    //   index = 2;
    // } else if (val.name === "person") {
    //   index = 3;
    // }
    // this.$store.commit("menu/bottomId", index);
    // },
  },
  created() {},
  methods: {
    handleChange(item) {
      if (item.index !== 1) {
        if (item.index !== this.bottomSelectedId) {
          this.$router.push(item.type);
          this.$store.commit("menu/bottomId", item.index);
        }
      } else {
        window.location.href = item.type;
      }
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
@import "../../styles/variables.scss";
.bottom-menu {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  // font-size: 0.14rem;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #eee;
  z-index: 1;
  height: 50px;
  font-size: 10px;
  .menu-tab {
    // width: 25%;
    flex: 1;
    height: 50px;
    text-align: center;
    padding-top: 0.1rem;
    padding-bottom: 0.05rem;
    box-sizing: border-box;
    img {
      width: 0.6rem;
      height: 0.6rem;
    }
    span {
      display: block;
    }
  }
  .menu-tab-active {
    color: $textColor;
  }
}
</style>
